import { LocationResponse, Places } from '@/types/Location.type';
import axiosInstance from '@/utils/https';

export const GetMenuProducts = async (params?: string) => {
  try {
    const url = `api/v1/menu?${params}`;
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetRelatedMenuProducts = async (category: string) => {
  try {
    const url = `api/v1/menu?category=${category}`;
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetAllUnauthorizedMenu = async () => {
  try {
    const url = `api/v1/menu/unauthenticated`;
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetUnauthorizedMenu = async (params?: string) => {
  try {
    const url = params
      ? `api/v1/menu/unauthenticated?${params}`
      : 'api/v1/menu/unauthenticated';
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const CreatetMenuProduct = async (payload: any) => {
  try {
    const url = `menu`;
    const response = await axiosInstance.post(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const EditMenuProduct = async (id: string, payload: any) => {
  try {
    const url = `menu/${id}`;
    const response = await axiosInstance.patch(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const DeleteMenuProducts = async (id: string) => {
  try {
    const url = `api/v1/menu-item/${id}`;
    const response = await axiosInstance.delete(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetMenuCombos = async (lat: number, long: number) => {
  try {
    const url = `menu-items/nearby?latitude=${lat}&longitude=${long}&combo=true`;
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const UpdateOutletMenuPrice = async (id: string, payload: any) => {
  try {
    const url = `menu/${id}/outlet`;
    const response = await axiosInstance.patch(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const ChangeOutletMenuAvailaibility = async (
  id: string,
  payload: any
) => {
  try {
    const url = `menu/outlet-menu/${id}`;
    const response = await axiosInstance.patch(url, payload);

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const GetOutletMenusForCustomer = async (id: string) => {
  try {
    const url = `api/v1/menu/unauthenticated?outlet_id=${id}`;
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetOutletByOrderCode = async (orderCode: string) => {
  try {
    const url = `api/v1/outlets/${orderCode}`;
    const response = await axiosInstance.get(url);

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const GetLocations = async (location: string) => {
  try {
    const url = `api/v1/outlets/map/autocomplete?input=${location}`;
    const response = await axiosInstance.get(url);

    return response.data as Places;
  } catch (error) {
    throw error;
  }
};
export const GetLocation = async (place_id: string) => {
  try {
    const url = `api/v1/outlets/maps?place_id=${place_id}`;
    const response = await axiosInstance.get(url);

    return response.data as LocationResponse;
  } catch (error) {
    throw error;
  }
};

export const GetProductCategory = async () => {
  try {
    const url = 'api/v1/menu/category';
    const res = await axiosInstance.get(url);

    return res.data;
  } catch (error) {
    throw error;
  }
};
